import { loadScript } from "@ts/Util/page";
document.addEventListener('DOMContentLoaded', async () => {
    await loadScript('/bower_components/waypoints/lib/noframework.waypoints.min.js');
    const _window = window;
    const waypoints = {
        header: {
            notSticky: false,
            sticky: false
        },
        footer: {
            invisible: false,
            visible: false
        }
    };
    new _window.Waypoint({
        element: document.querySelector('.inner-wrap'),
        handler: (direction) => {
            waypoints.header.notSticky = direction === 'up';
            waypoints.header.sticky = direction === 'down';
            document.dispatchEvent(new CustomEvent('waypoints.layout', { detail: waypoints }));
        },
        offset: -67
    });
    new _window.Waypoint({
        element: document.getElementById('layout-page-content'),
        handler: (direction) => {
            waypoints.footer.invisible = direction === 'up';
            waypoints.footer.visible = direction === 'down';
            document.dispatchEvent(new CustomEvent('waypoints.layout', { detail: waypoints }));
        },
        offset: 'bottom-in-view'
    });
    if (_window.requestAnimationFrame) {
        setTimeout(() => {
            _window.requestAnimationFrame(_window.Waypoint.refreshAll);
        });
    }
});
